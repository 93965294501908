<template>
  <div class="claim-requests-container">
    <CCard>
      <CCardHeader>
        <CRow>
          <CCol md="9" sm="12">
            <h1>{{$t("CLAIM_REQUEST_LIST_PAGE_TITLE_PAYOUT_REQUEST")}}</h1>
          </CCol>
          <CCol md="3" sm="12">
            <CButton
              class="btn btn-upload-csv"
              @click="showUploadCsvModal"
            >{{$t("CLAIM_REQUEST_LIST_PAGE_BUTTON_UPLOAD_CSV")}}</CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody>
        <SearchClaimRequestsHeader
          :onSearch="onSearch"
          :options="options"
          :downloadCSV="downloadCSV"
          :isAllowedDownloadCSV="isAllowedDownloadCSV"
        />

        <div class="checkbox-section mb-1">
          <label v-show="checkboxMode === 1" class="checkbox-label selected-all-checkbox">
            <input v-indeterminate="null" type="checkbox" @change="onChangeSelectedAll($event)" />
          </label>

          <label v-show="checkboxMode === 0" class="checkbox-label selected-all-checkbox">
            <input type="checkbox" v-model="isSelectedAll" @change="onChangeSelectedAll($event)" />
          </label>

          <button
            class="btn btn-update-status"
            v-if="isAllowedApprove"
            @click="showPayoutTransferedModal()"
          >{{$t("CLAIM_REQUEST_LIST_PAGE_LABEL_STATUS")}}</button>
        </div>
        <ClaimRequestListTable
          :limit="limit"
          :activePage="activePage"
          :items="items"
          :total="total"
          :isSelectedAll="isSelectedAll"
          :isAllowedViewDetails="isAllowedViewDetails"
          :isAllowedApprove="isAllowedApprove"
          :isAllowedReject="isAllowedReject"
          @selectItem="selectItem"
        />
        <StakingPagination
          :limit="limit"
          :total="total"
          :activePage="activePage"
          @changePage="onChangePage"
          @changePageSize="onChangePageSize"
        />

        <CModal
          class="staking-confirmation-modal payout-transferred-confirmation-modal"
          :show.sync="isShownPayoutTransferedModal"
          :centered="true"
        >
          <template #header>
            <CButtonClose class="close-modal" @click="isShownPayoutTransferedModal = false" />
          </template>
          <div>
            <div class="pb-4 tit-dele">
              <h4>{{$t("CLAIM_REQUEST_LIST_PAGE_TITLE_PAYOUT_TRANSFERRED")}}</h4>
            </div>
            <div class="text-modal">
              <CCard class="selected-claim-request-list-table-card">
                <CDataTable :items="selectedItems" :fields="fields" fixed hover striped bordered>
                  <template #email="{item}">
                    <td>
                      <StakingMemberDetailsLink :text="item.member.email" />
                    </td>
                  </template>

                  <template #amount="{item}">
                    <td>
                      <StakingCurrency :value="item.amount" :currencySymbol="item.currency_symbol" />
                    </td>
                  </template>
                </CDataTable>
              </CCard>

              <div
                class="notification"
                v-if="isShownErrorMessage"
              >{{$t('CLAIM_REQUEST_LIST_PAGE_PAYOUT_TRANSFERRED_MODAL_ERROR')}}</div>

              <div class="grand-total">
                <div class="row">
                  <div class="col-md-7 text-right">
                    <span>{{$t("CLAIM_REQUEST_LIST_PAGE_TEXT_GRAND_TOTAL")}}</span>
                  </div>
                  <div class="col-md-5 text-right">
                    <StakingCurrency :value="totalAmount" :currencySymbol="currencySymbol" />
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-7 text-right">
                    <span>{{$t("CLAIM_REQUEST_LIST_PAGE_TEXT_TIMESTAMP")}}</span>
                  </div>
                  <div class="col-md-5 text-right">
                    <span>{{ timestamp | moment(dateFormat) }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <template #footer>
            <CButton
              class="btn-cancel"
              @click="isShownPayoutTransferedModal = false"
              color="dark"
              variant="outline"
            >{{$t("CLAIM_REQUEST_LIST_PAGE_BUTTON_CANCEL")}}</CButton>

            <CButton
              @click="updatePayoutTransferred"
              class="btn-ok"
            >{{$t("CLAIM_REQUEST_LIST_PAGE_BUTTON_PAYOUT_TRANSFERRED")}}</CButton>
          </template>
        </CModal>
      </CCardBody>
    </CCard>
    <UploadCSVModal
      :isOpenModal="isOpenModal"
      :postTo="postTo"
      :keyTxIdName="keyTxIdName"
      @close-modal="handleUploadCsvModal"
    />
  </div>
</template>
<script>
import _ from 'lodash';
import moment from 'moment';
import { saveAs } from 'file-saver';
import { mapState, mapActions, mapGetters } from 'vuex';
import ClaimRequestListTable from '@/components/ClaimRequest/ClaimRequestListTable';
import SearchClaimRequestsHeader from '@/components/ClaimRequest/SearchClaimRequestsHeader';
import StakingPagination from '@/components/StakingPagination';
import StakingCurrency from '@/components/StakingCurrency';
import StakingBadge from '@/components/StakingBadge';
import StakingMemberDetailsLink from '@/components/StakingMemberDetailsLink';
import { endpoints, env, Constants } from '@/constants';
import responseHelper from '@/utils/response-helper';
import stringHelper from '@/utils/string-helper';
import { ClaimRequestStatus } from '@/enums';
import UploadCSVModal from '@/components/UploadCSVModal/index';
import sessionStorageHelper from '@/utils/sessionStorage-helper';
import dateTimeHelper from '@/utils/datetime-helper';

export default {
  name: 'ClaimRequestList',
  components: {
    ClaimRequestListTable,
    SearchClaimRequestsHeader,
    StakingPagination,
    StakingCurrency,
    StakingMemberDetailsLink,
    UploadCSVModal,
  },
  data() {
    return {
      isOpenModal: false,
      postTo: endpoints.uploadTxIdClaimRequest,
      keyTxIdName: 'claimRequestTxid',
      isIndeterminate: false,
      isSelectedAll: false,
      checkboxMode: 0,
      items: [],
      options: [],
      filterData: {},
      total: 0,
      limit: env.app.pageSize,
      activePage: 1,
      isAllowedDownloadCSV: false,
      isShownPayoutTransferedModal: false,
      isAllowedViewDetails: false,
      isAllowedApprove: false,
      isAllowedReject: false,
      selectedItem: null,
      isProcessing: false,
      dateFormat: Constants.dateFormat,
      selectedItems: [],
      totalAmount: 0,
      currencySymbol: '',
      isShownErrorMessage: false,
      timestamp: new Date(),
      fields: [
        {
          key: 'email',
          label: this.$t('CLAIM_REQUEST_LIST_PAGE_LABEL_EMAIL'),
        },
        {
          key: 'amount',
          label: this.$t('CLAIM_REQUEST_LIST_PAGE_LABEL_AMOUNT'),
        },
      ],
    };
  },
  computed: {
    ...mapGetters(['userPermissions']),
    offset() {
      return (this.activePage - 1) * this.limit;
    },
    pages() {
      return Math.ceil(this.total / this.limit);
    },
  },
  async mounted() {
    this.checkPermissions();
    this.options = this.membershipTypes;
  },

  methods: {
    checkPermissions() {
      if (this.userPermissions.length > 0) {
        this.userPermissions.forEach(item => {
          if (item.name === 'MEMBERSHIP_EXPORT_CSV_CLAIM_REQUESTS') {
            this.isAllowedDownloadCSV = true;
          }

          if (item.name === 'MEMBERSHIP_VIEW_CLAIM_REQUEST_DETAIL') {
            this.isAllowedViewDetails = true;
          }

          if (item.name === 'MEMBERSHIP_APPROVE_CLAIM_REQUEST') {
            this.isAllowedApprove = true;
          }
        });
      }
    },
    async getClaimRequests(data) {
      data = data || {};

      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;

      try {
        const params = {
          limit: this.limit,
          offset: this.offset,
          from_date: filterData.fromDate,
          to_date: dateTimeHelper.adjustToDate(filterData.toDate),
          email: filterData.email,
          last_name: filterData.lastName,
          first_name: filterData.firstName,
          memo: filterData.memo,
          status: filterData.status,
          payout_from_date: filterData.payoutFromDate,
          payout_to_date: dateTimeHelper.adjustToDate(filterData.payoutToDate),
        };
        const result = await this.$http.get(endpoints.getClaimRequests, { params });

        this.items = result.data.items || [];
        this.items.forEach(item => {
          item.status = ClaimRequestStatus[item.status];
          item.id = stringHelper.padDigits(item.id, 4);
          item.amount = Number(item.amount);
        });

        this.total = result.data.total;

        this.isSelectedAll = false;
        this.isIndeterminate = false;
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('CLAIM_REQUEST_LIST_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    async onChangePage(page) {
      this.activePage = page;

      this.getClaimRequests();
    },
    async onChangePageSize(value) {
      this.limit = value;
      this.activePage = 1;

      this.getClaimRequests();
    },
    async onSearch(searchData) {
      const data = searchData || sessionStorageHelper.getData(sessionStorageHelper.KEYS.FilterClaimRequests);
      this.activePage = 1;

      this.getClaimRequests(data);
    },
    async downloadCSV(data) {
      this.filterData = {
        ...this.filterData,
        ...data,
      };
      const filterData = this.filterData;

      try {
        const params = {
          from_date: filterData.fromDate,
          to_date: dateTimeHelper.adjustToDate(filterData.toDate),
          email: filterData.email,
          last_name: filterData.lastName,
          first_name: filterData.firstName,
          memo: filterData.memo,
          status: filterData.status,
          payout_from_date: filterData.payoutFromDate,
          payout_to_date: dateTimeHelper.adjustToDate(filterData.payoutToDate),
          time_offset: new Date().getTimezoneOffset(),
        };
        const result = await this.$http.get(endpoints.downloadCsvClaimRequests, { params });
        const data = result;
        const fileUrl = 'commission-payout.dat';
        const contentType = 'application/octet-stream';
        const file = new Blob([data], { type: contentType });
        saveAs(file, fileUrl);
      } catch (err) {
        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('CLAIM_REQUEST_LIST_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    onChangeSelectedAll() {
      if (this.checkboxMode === 1) {
        this.isSelectedAll = false;
      }

      this.checkboxMode = 0;
      this.isIndeterminate = false;
      this.items.forEach(item => (item.isSelected = this.isSelectedAll));

      this.items = this.items.splice(0);
    },
    selectItem(item) {
      const len = this.items.length;
      const numOfSelectedItems = this.items.filter(x => x.isSelected).length;

      if (numOfSelectedItems === 0) {
        this.isIndeterminate = false;
        this.isSelectedAll = false;
        this.checkboxMode = 0;
      } else if (numOfSelectedItems === len) {
        this.isIndeterminate = false;
        this.isSelectedAll = true;
        this.checkboxMode = 0;
      } else {
        this.isSelectedAll = false;
        this.isIndeterminate = true;
        this.checkboxMode = 1;
      }
    },
    showPayoutTransferedModal() {
      const selectedItems = (this.selectedItems = this.items.filter(x => x.isSelected));
      const numOfSelectedItems = selectedItems.length;
      if (!numOfSelectedItems) {
        return;
      }

      this.timestamp = new Date();
      this.totalAmount = _.sumBy(selectedItems, 'amount');
      this.currencySymbol = selectedItems[0].currency_symbol;

      this.isShownPayoutTransferedModal = true;
      this.isShownErrorMessage = false;
    },
    async updatePayoutTransferred() {
      const hasApprovedItem = this.items.filter(x => x.isSelected).some(item => item.status === ClaimRequestStatus.Approved);
      if (hasApprovedItem) {
        this.isShownErrorMessage = true;
        return;
      }

      this.isShownErrorMessage = false;

      this.updateClaimRequest(ClaimRequestStatus.Approved);
    },
    async updateClaimRequest(status) {
      if (this.isProcessing) {
        return;
      }

      this.isProcessing = true;

      try {
        const data = {
          claimRequestIds: this.items.filter(x => x.isSelected).map(x => Number(x.id)),
          // status,
        };
        const result = await this.$http.put(endpoints.approveClaimRequests, data);

        this.isProcessing = false;
        this.isShownPayoutTransferedModal = false;
        this.getClaimRequests();
      } catch (err) {
        this.isProcessing = false;

        this.$notify({
          group: 'notifications',
          type: 'error',
          title: this.$t('CLAIM_REQUEST_LIST_PAGE_TITLE_NOTIFY_ERROR'),
          text: responseHelper.getErrorMessage(err),
        });
      }
    },
    showUploadCsvModal() {
      this.isOpenModal = true;
    },
    handleUploadCsvModal(isRefeshData) {
      this.isOpenModal = false;
      if (isRefeshData) {
        this.getClaimRequests();
      }
    },
  },
};
</script>
<style lang="scss">
.claim-requests-container {
  padding: 0 24px;
  @media screen and (max-width: 1024px) {
    padding: 0 15px;
  }

  .checkbox-section {
    .selected-all-checkbox {
      width: 40px;
      text-align: center;
    }

    .btn-update-status {
      width: 111px;
      border: 1px solid #1a2958;
      border-radius: 15px;
      background-color: #ffffff;
      color: #4d5666;
      font-size: 12px;
      letter-spacing: 0;
      line-height: 12px;
    }
  }

  .card {
    > .card-header {
      border-bottom: none;
      padding-bottom: 0px;

      h1 {
        color: #657187;
        font-size: 18px;
        font-weight: 600;
        line-height: 34px;
        margin-bottom: 0px;
      }
      .btn-upload-csv {
        border: none;
        border-radius: 32px;
        height: 32px;
        padding: 0 30px;
        color: #fff;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 14px;
        text-align: center;
        border: 1px solid #1a2958;
        border-radius: 17.5px;
        background-color: #1a2958;
        width: 100%;
        color: #5da30b;
        font-weight: 500;
        letter-spacing: 0.5px;
        line-height: 16px;
        text-align: center;
        border: 1px solid #5da30b;
        border-radius: 16px;
        background-color: #ffffff;
      }
    }

    .notification {
      color: red;
      margin: 5px 0 20px 0;
      font-size: 13px;
    }
  }
}

.staking-confirmation-modal.payout-transferred-confirmation-modal {
  // .selected-claim-request-list-table-card {
  // }

  .grand-total {
    padding: 0px 15px;
    .staking-currency {
      color: #4d5666;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: 0.5px;
      text-align: right;
    }
  }

  .modal-footer {
    .btn-ok {
      width: 200px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.5px;
      line-height: 16px;
      text-align: center;
      border-radius: 4px;
      background-color: #5da30b;
    }
  }
}
</style>
