<template>
  <div class="search-claim-requests-header-container">
    <ValidationObserver ref="form">
      <CRow>
        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_CLAIM_REQUEST_HEADER_COMPONENT_FIELD_NAME_VALIDATE_DATE_RANGE"
            v-slot="{ errors }"
            rules
          >
            <div role="group" class="form-group">
              <label class>{{$t("SEARCH_CLAIM_REQUEST_HEADER_COMPONENT_LABEL_DATE_RANGE")}}</label>
              <div class="input-group1">
                <date-range-picker
                  v-model="searchData.dateRange"
                  :locale-data="dateRangeOption.localeData"
                  :always-show-calendars="dateRangeOption.alwaysShowCalendars"
                  :append-to-body="dateRangeOption.appendToBody"
                  :ranges="dateRangeOption.showRanges"
                  :auto-apply="dateRangeOption.autoApply"
                ></date-range-picker>
              </div>
            </div>
            <span class="error-msg-wrap">
              <span class="error-msg">{{errors[0]}}</span>
            </span>
          </ValidationProvider>
        </CCol>

        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_CLAIM_REQUEST_HEADER_COMPONENT_FIELD_NAME_VALIDATE_LAST_NAME"
            v-slot="{ errors }"
            rules
          >
            <CInput
              :label="$t('SEARCH_CLAIM_REQUEST_HEADER_COMPONENT_LABEL_LAST_NAME')"
              type="text"
              v-model="searchData.lastName"
              class="form-group"
              @keyup.enter="search()"
            />
            <CRow>
              <CCol :md="12">
                <span class="error-msg mt-1">{{errors[0]}}</span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </CCol>

        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_CLAIM_REQUEST_HEADER_COMPONENT_FIELD_NAME_VALIDATE_FIRST_NAME"
            v-slot="{ errors }"
            rules
          >
            <CInput
              :label="$t('SEARCH_CLAIM_REQUEST_HEADER_COMPONENT_LABEL_FIRST_NAME')"
              type="text"
              v-model="searchData.firstName"
              class="form-group"
              @keyup.enter="search()"
            />
            <CRow>
              <CCol :md="12">
                <span class="error-msg mt-1">{{errors[0]}}</span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </CCol>

        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_CLAIM_REQUEST_HEADER_COMPONENT_FIELD_NAME_VALIDATE_EMAIL"
            v-slot="{ errors }"
            rules
          >
            <CInput
              :label="$t('SEARCH_CLAIM_REQUEST_HEADER_COMPONENT_LABEL_EMAIL')"
              type="text"
              v-model="searchData.email"
              class="form-group"
              @keyup.enter="search()"
            />
            <CRow>
              <CCol :md="12">
                <span class="error-msg mt-1">{{errors[0]}}</span>
              </CCol>
            </CRow>
          </ValidationProvider>
        </CCol>

        <CCol md="3" sm="12">
          <div role="group" class="form-group form-group">
            <label class>{{$t("SEARCH_CLAIM_REQUEST_HEADER_COMPONENT_LABEL_STATUS")}}</label>
            <select v-model="searchData.status" class="form-control">
              <option value>{{$t("SEARCH_CLAIM_REQUEST_HEADER_COMPONENT_LABEL_OPTION_ALL")}}</option>
              <option
                v-for="item in claimRequestStatuses"
                :key="item.value"
                :value="item.value"
              >{{item.label}}</option>
            </select>
          </div>
        </CCol>

        <CCol md="3" sm="12">
          <ValidationProvider
            name="SEARCH_CLAIM_REQUEST_HEADER_COMPONENT_FIELD_NAME_VALIDATE_PAYMENT_TRANSFERRED"
            v-slot="{ errors }"
            rules
          >
            <div role="group" class="form-group">
              <label class>{{$t("SEARCH_CLAIM_REQUEST_HEADER_COMPONENT_LABEL_PAYMENT_TRANSFERRED")}}</label>
              <div class="input-group1">
                <date-range-picker
                  v-model="searchData.paymentTransferredDateRange"
                  :locale-data="dateRangeOption.localeData"
                  :always-show-calendars="dateRangeOption.alwaysShowCalendars"
                  :append-to-body="dateRangeOption.appendToBody"
                  :ranges="dateRangeOption.showRanges"
                  :auto-apply="dateRangeOption.autoApply"
                ></date-range-picker>
              </div>
            </div>
            <span class="error-msg-wrap">
              <span class="error-msg">{{errors[0]}}</span>
            </span>
          </ValidationProvider>
        </CCol>
      </CRow>
    </ValidationObserver>

    <CRow class="mt-2 form-actions">
      <CCol md="3" sm="12">
        <CButton
          class="btn btn-download-csv"
          v-if="isAllowedDownloadCSV"
          @click="onDownloadCSV()"
        >{{$t("SEARCH_CLAIM_REQUEST_HEADER_COMPONENT_BUTTON_DOWNLOAD_CSV")}}</CButton>
      </CCol>

      <CCol md="3"></CCol>

      <CCol md="3" sm="12">
        <CButton
          class="btn btn-reset"
          @click="resetForm()"
        >{{$t("SEARCH_CLAIM_REQUEST_HEADER_COMPONENT_BUTTON_RESET")}}</CButton>
      </CCol>

      <CCol md="3" sm="12">
        <CButton
          class="btn btn-search"
          @click="search()"
        >{{$t("SEARCH_CLAIM_REQUEST_HEADER_COMPONENT_BUTTON_SEARCH")}}</CButton>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import moment from 'moment';
import { getClaimRequestStatuses, getCryptoPlatformTypes, getMemberAccountTypes } from '@/enums';
import { Constants } from '@/constants';
import DateRangePicker from 'vue2-daterange-picker';
import stringHelper from '@/utils/string-helper';
import sessionStorageHelper from '@/utils/sessionStorage-helper';
import dateTimeHelper from '@/utils/datetime-helper';

export default {
  name: 'SearchClaimRequestsHeader',
  components: {
    DateRangePicker,
  },
  data() {
    return {
      searchData: sessionStorageHelper.getData(sessionStorageHelper.KEYS.FilterClaimRequests) || this.getDefaultValues(),
      dateRangeOption: {
        alwaysShowCalendars: true,
        appendToBody: false,
        showRanges: false,
        autoApply: false,
        localeData: {
          firstDay: 1,
          format: 'yyyy/mm/dd',
          direction: 'ltr',
          separator: ' - ',
          applyLabel: 'Apply',
          cancelLabel: 'Cancel',
          weekLabel: 'W',
        },
      },
      claimRequestStatuses: stringHelper.translateTextForDropdownList(getClaimRequestStatuses(), 'CLAIM_REQUEST_STATUS'),
      dateTimePickerFormat: Constants.dateTimePickerFormat,
    };
  },
  props: {
    onSearch: {
      type: Function,
      default: null,
    },
    downloadCSV: {
      type: Function,
      default: null,
    },
    options: {
      type: Array,
      default: () => [],
    },
    isAllowedDownloadCSV: {
      type: Boolean,
      default: false,
    },
  },
  async mounted() {
    this.search();
  },
  methods: {
    getDefaultValues() {
      return {
        email: '',
        lastName: '',
        firstName: '',
        status: '',
        paymentType: '',
        cryptoPlatform: '',
        dateRange: {
          startDate: null,
          endDate: null,
        },
        paymentTransferredDateRange: {
          startDate: null,
          endDate: null,
        },
      };
    },
    resetForm() {
      this.searchData = this.getDefaultValues();

      this.search();
    },

    async search() {
      this.searchData.dateRange.startDate = dateTimeHelper.startOfDate(this.searchData.dateRange.startDate);
      this.searchData.dateRange.endDate = dateTimeHelper.startOfDate(this.searchData.dateRange.endDate);
      this.searchData.paymentTransferredDateRange.startDate = dateTimeHelper.startOfDate(
        this.searchData.paymentTransferredDateRange.startDate,
      );
      this.searchData.paymentTransferredDateRange.endDate = dateTimeHelper.startOfDate(this.searchData.paymentTransferredDateRange.endDate);
      sessionStorageHelper.setData(sessionStorageHelper.KEYS.FilterClaimRequests, this.searchData);

      const searchData = {
        ...this.searchData,
        fromDate: this.searchData.dateRange.startDate,
        toDate: this.searchData.dateRange.endDate,
        payoutFromDate: this.searchData.paymentTransferredDateRange.startDate,
        payoutToDate: this.searchData.paymentTransferredDateRange.endDate,
      };
      this.onSearch(searchData);
    },
    async onDownloadCSV() {
      const searchData = {
        ...this.searchData,
        fromDate: this.searchData.dateRange.startDate,
        toDate: this.searchData.dateRange.endDate,
        payoutFromDate: this.searchData.paymentTransferredDateRange.startDate,
        payoutToDate: this.searchData.paymentTransferredDateRange.endDate,
      };

      this.$refs.form.validate().then(success => {
        if (!success) {
          return;
        }

        this.downloadCSV(searchData);
      });
    },
  },
};
</script>

<style lang="scss">
.search-claim-requests-header-container {
  margin-bottom: 35px;
  .form-search {
    margin-bottom: 5px;
  }

  .vue-daterange-picker {
    width: 100%;
    .reportrange-text {
    }
    .daterangepicker {
      position: absolute;
      top: 35px;
      left: 0;
      transform: unset;
      padding: 0;
      z-index: 99;
      &::after,
      &::before {
        left: 80px;
        right: unset;
      }
      .calendars-container {
        flex-wrap: wrap;
      }
    }
    .fa.fa-calendar {
      display: none;
    }
  }

  .form-actions {
    .btn {
      border: none;
      border-radius: 32px;
      height: 32px;
      padding: 0 30px;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 14px;
      text-align: center;
      border: 1px solid #1a2958;
      border-radius: 17.5px;
      background-color: #1a2958;
      width: 100%;
      display: block;

      &.btn-search {
      }

      &.btn-reset,
      &.btn-download-csv {
        color: #1a2958;
        background-color: #fff;
      }
    }
  }
}
</style>
