var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"claim-request-table-container"},[_c('CCard',{staticClass:"claim-request-list-table-card"},[_c('CDataTable',{attrs:{"items":_vm.items,"fields":_vm.fields,"fixed":"","hover":"","striped":"","bordered":""},scopedSlots:_vm._u([{key:"isSelected",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('label',{staticClass:"checkbox-label"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(item.isSelected),expression:"item.isSelected"}],attrs:{"type":"checkbox"},domProps:{"checked":Array.isArray(item.isSelected)?_vm._i(item.isSelected,null)>-1:(item.isSelected)},on:{"change":[function($event){var $$a=item.isSelected,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(item, "isSelected", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(item, "isSelected", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(item, "isSelected", $$c)}},function($event){return _vm.onSelectItem(item)}]}})])])]}},{key:"created_at",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(_vm._f("moment")(item.created_at,_vm.dateFormat)))])]}},{key:"first_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.member.first_name || '-'))])]}},{key:"last_name",fn:function(ref){
var item = ref.item;
return [_c('td',[_vm._v(_vm._s(item.member.last_name || '-'))])]}},{key:"email",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingMemberDetailsLink',{attrs:{"id":item.member.id,"text":item.member.email}})],1)]}},{key:"amount",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingCurrency',{attrs:{"value":item.amount,"currencySymbol":item.currency_symbol}})],1)]}},{key:"status",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('StakingBadge',{attrs:{"type":"claim-request-status","text":item.status}})],1)]}},{key:"payout_transferred",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.payout_transferred)?_c('span',[_vm._v(_vm._s(_vm._f("moment")(item.payout_transferred,_vm.dateFormat)))]):_vm._e()])]}},{key:"txid",fn:function(ref){
var item = ref.item;
return [_c('td',[(item.txid)?_c('StakingExplorerLink',{attrs:{"type":"tx","displayText":_vm.$t('CLAIM_REQUEST_LIST_TABLE_COMPONENT_LINK_TEXT'),"currencySymbol":item.currency_symbol,"value":item.txid}}):_vm._e()],1)]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('td',{attrs:{"width":"50px"}},[(_vm.isAllowedViewDetails)?_c('button',{staticClass:"btn btn-view-details",on:{"click":function($event){return _vm.viewDetails(item)}}},[_c('img',{staticClass:"hand edit-icon",attrs:{"src":require("@/assets/images/ico-edit-user.svg"),"alt":""}})]):_vm._e()])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }