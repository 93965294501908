<template>
  <div class="claim-request-table-container">
    <CCard class="claim-request-list-table-card">
      <CDataTable :items="items" :fields="fields" fixed hover striped bordered>
        <template #isSelected="{item}">
          <td>
            <label class="checkbox-label">
              <input type="checkbox" v-model="item.isSelected" @change="onSelectItem(item)" />
            </label>
          </td>
        </template>
        <template #created_at="{item}">
          <td>{{item.created_at | moment(dateFormat)}}</td>
        </template>

        <template #first_name="{item}">
          <td>{{item.member.first_name || '-'}}</td>
        </template>

        <template #last_name="{item}">
          <td>{{item.member.last_name || '-'}}</td>
        </template>

        <template #email="{item}">
          <td>
            <StakingMemberDetailsLink :id="item.member.id" :text="item.member.email" />
          </td>
        </template>

        <template #amount="{item}">
          <td>
            <StakingCurrency :value="item.amount" :currencySymbol="item.currency_symbol" />
          </td>
        </template>

        <template #status="{item}">
          <td>
            <StakingBadge type="claim-request-status" :text="item.status" />
          </td>
        </template>

        <template #payout_transferred="{item}">
          <td>
            <span v-if="item.payout_transferred">{{item.payout_transferred | moment(dateFormat)}}</span>
          </td>
        </template>

        <template #txid="{item}">
          <td>
            <StakingExplorerLink
              v-if="item.txid"
              type="tx"
              :displayText="$t('CLAIM_REQUEST_LIST_TABLE_COMPONENT_LINK_TEXT')"
              :currencySymbol="item.currency_symbol"
              :value="item.txid"
            />
          </td>
        </template>

        <template #action="{item}">
          <td width="50px">
            <button
              class="btn btn-view-details"
              v-if="isAllowedViewDetails"
              @click="viewDetails(item)"
            >
              <img src="@/assets/images/ico-edit-user.svg" alt class="hand edit-icon" />
            </button>
          </td>
        </template>
      </CDataTable>
    </CCard>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import StakingCurrency from '@/components/StakingCurrency';
import StakingBadge from '@/components/StakingBadge';
import StakingMemberDetailsLink from '@/components/StakingMemberDetailsLink';
import { Constants } from '@/constants';
import StakingExplorerLink from '@/components/StakingExplorerLink';

export default {
  name: 'ClaimRequestListTable',
  components: {
    StakingCurrency,
    StakingBadge,
    StakingMemberDetailsLink,
    StakingExplorerLink,
  },
  data() {
    return {
      dateFormat: Constants.dateFormat,
      fields: [
        {
          key: 'isSelected',
          label: '',
          _style: 'width: 40px',
        },
        {
          key: 'id',
          label: this.$t('CLAIM_REQUEST_LIST_TABLE_COMPONENT_LABEL_NUMBER'),
        },
        {
          key: 'created_at',
          label: this.$t('CLAIM_REQUEST_LIST_TABLE_COMPONENT_LABEL_REQUESTED'),
          _style: 'min-width: 150px; text-align: left;',
        },
        {
          key: 'first_name',
          label: this.$t('CLAIM_REQUEST_LIST_TABLE_COMPONENT_LABEL_FIRST_NAME'),
        },
        {
          key: 'last_name',
          label: this.$t('CLAIM_REQUEST_LIST_TABLE_COMPONENT_LABEL_LAST_NAME'),
        },
        {
          key: 'email',
          label: this.$t('CLAIM_REQUEST_LIST_TABLE_COMPONENT_LABEL_EMAIL'),
        },
        {
          key: 'amount',
          label: this.$t('CLAIM_REQUEST_LIST_TABLE_COMPONENT_LABEL_AMOUNT'),
        },
        {
          key: 'status',
          label: this.$t('CLAIM_REQUEST_LIST_TABLE_COMPONENT_LABEL_STATUS'),
        },
        {
          key: 'payout_transferred',
          label: this.$t('CLAIM_REQUEST_LIST_TABLE_COMPONENT_LABEL_PAYOUT_TRANSFERRED'),
          _style: 'min-width: 150px; text-align: left;',
        },
        {
          key: 'txid',
          label: this.$t('CLAIM_REQUEST_LIST_TABLE_COMPONENT_LABEL_TXID'),
          _style: 'width: 70px',
        },
        {
          key: 'action',
          label: '',
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    activePage: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
    isSelectedAll: {
      type: Boolean,
      default: false,
    },
    isAllowedViewDetails: {
      type: Boolean,
      default: false,
    },
    isAllowedApprove: {
      type: Boolean,
      default: false,
    },
    isAllowedReject: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateStatus() {},
    onSelectItem(item) {
      this.$emit('selectItem', item);
    },
    viewDetails(item) {
      this.$router.push(`commission-payout/${item.id}/details`);
    },
  },
};
</script>

<style lang="scss">
.claim-request-table-container {
  .claim-request-list-table-card {
    .table {
      margin-bottom: 0;
      .hand {
        cursor: pointer;
      }
      .btn {
        font-size: 14px;
        font-weight: 400;
        color: #0505a5;
        outline: none;
        padding-top: 0;
        padding-bottom: 0;
      }
    }
  }
}
</style>
